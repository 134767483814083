var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v(" Orders ")
      ]),
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 10 } },
        _vm._l(_vm.orderStatus, function(status) {
          return _c(
            "el-col",
            {
              key: status.status,
              staticClass: "card-panel-col",
              attrs: { xs: 12, sm: 6, lg: 4 }
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/order/list?status=" + status.status } },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-description" },
                      [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v(" " + _vm._s(status.status) + " ")
                        ]),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val": status.count,
                            duration: 2600
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        }),
        1
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v(" Bids ")
      ]),
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 10 } },
        _vm._l(_vm.bidStatus, function(status) {
          return _c(
            "el-col",
            {
              key: status.status,
              staticClass: "card-panel-col",
              attrs: { xs: 12, sm: 6, lg: 4 }
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/bid/list?status=" + status.status } },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-description" },
                      [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v(" " + _vm._s(status.status) + " ")
                        ]),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val": status.count,
                            duration: 2600
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              [
                _c("router-link", { attrs: { to: "/users/list" } }, [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-people" },
                      [
                        _c("svg-icon", {
                          staticClass: "card-panel-icon",
                          attrs: { name: "peoples" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "card-panel-description" },
                      [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v(" Users ")
                        ]),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val": _vm.userscount,
                            duration: 2600
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]
            ],
            2
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              [
                _c("router-link", { attrs: { to: "/users-documents/list" } }, [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-message" },
                      [
                        _c("svg-icon", {
                          staticClass: "card-panel-icon",
                          attrs: { name: "message" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "card-panel-description" },
                      [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v(" Distributer"),
                          _c("br"),
                          _vm._v("Request ")
                        ]),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val": _vm.distributerRequestCount,
                            duration: 2600
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]
            ],
            2
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              [
                _c("router-link", { attrs: { to: "/product/list" } }, [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-shopping" },
                      [
                        _c("svg-icon", {
                          staticClass: "card-panel-icon",
                          attrs: { name: "shopping" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "card-panel-description" },
                      [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v(" Products ")
                        ]),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val": _vm.productscount,
                            duration: 3000
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }