import request from '@/utils/request';
export var fileTransferHeaders = {};
export var defaultOrdersTicketData = {
  id: 0,
  enabled: true,
  userId: 0,
  orderId: null,
  status: 'Open',
  closureComments: '',
  comments: '',
  attachment1: '',
  attachment2: '',
  attachment3: '',
  user: {
    id: null
  },
  order: {
    id: null
  }
};
export var getOrdersTickets = function getOrdersTickets(params) {
  return request({
    url: '/orders-tickets',
    method: 'get',
    params: params
  });
};
export var getOrdersTicketById = function getOrdersTicketById(id) {
  return request({
    url: "/orders-tickets/".concat(id),
    method: 'get'
  });
};
export var updateOrdersTicket = function updateOrdersTicket(id, data) {
  return request({
    url: "/orders-tickets/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteOrdersTicket = function deleteOrdersTicket(id) {
  return request({
    url: "/orders-tickets/".concat(id),
    method: 'delete'
  });
};
export var createOrdersTicket = function createOrdersTicket(data) {
  return request({
    url: '/orders-tickets/',
    method: 'post',
    data: data
  });
};
export var ordersTicketsCount = function ordersTicketsCount(params) {
  return request({
    url: '/orders-tickets/count',
    method: 'get',
    params: params
  });
};